import React, { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Select, InputNumber, Input } from 'antd'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { CREATE_ONE_SERVICE, FIND_MANY_CATEGORY, FIND_UNIQUE_CATEGORY } from '../gqls'

import { useCompany } from '../hooks/company'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const AddService = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [categories, setCategories] = useState([])

    const { company } = useCompany()

    const [createOneServiceMutation, { loading, error }] = useMutation(CREATE_ONE_SERVICE, {
        onCompleted: () => {
            form.resetFields()
            message.success('Услуга добавлена')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    useQuery(FIND_MANY_CATEGORY, {
        onCompleted: ({ findManyCategory }) => {
            const data = findManyCategory.map(object => ({
                value: object.id,
                label: `${object.name} / ${object.subcategory}`
            }))
            setCategories(data)
        },
        fetchPolicy: 'network-only'
    })

    if (loading || error) return null

    const handleSubmit = async (values) => {
        await createOneServiceMutation({
            variables: {
                data: {
                    price: values.price,
                    category: {
                        connect: {
                            id: values.category
                        }
                    },
                    company: {
                        connect: {
                            id: company.id
                        }
                    }
                }
            }
        })
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='category' label='Выберите категорию' rules={[rules.required]}>
                    <Select
                        placeholder='Выберите категорию'
                        options={categories}
                        optionFilterProp='label'
                        showSearch
                    />
                </Form.Item>
                <Form.Item name='price' label='Цена' rules={[rules.required]}>
                    <InputNumber placeholder='Цена' />
                </Form.Item>
                <Button loading={loading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddService
