import React, { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Upload } from 'antd'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { CREATE_ONE_CATEGORY } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const AddCategory = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [image, setImage] = useState([])

    const [createOneCategoryMutation, { loading: createOneCategoryLoading }] = useMutation(CREATE_ONE_CATEGORY, {
        onCompleted: () => {
            form.resetFields()
            message.success('Категория добавлена')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const handleSubmit = async (values) => {
        const createImage = image[0]?.response?.filename || null

        await createOneCategoryMutation({
            variables: {
                data: {
                    name: values.name,
                    subcategory: values.subcategory
                }
            }
        })
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='name' label='Название категории' rules={[rules.required]}>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item name='subcategory' label='Название подкатегории' rules={[rules.required]}>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Button loading={createOneCategoryLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddCategory
