import { gql } from "@apollo/client"

export const FIND_ME_USER = gql`
	{
		findMeUser{
			id
			createdAt
			updatedAt
			phone
			code
			name
			pushId
		}
	}
`
export const FIND_UNIQUE_USER = gql`
	query(
		$where: UserWhereUniqueInput!
	){
		findUniqueUser(
			where: $where
		){
			id
			createdAt
			updatedAt
			phone
			code
			name
			pushId
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
		}
	}
`
export const FIND_FIRST_USER = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findFirstUser(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			phone
			code
			name
			pushId
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
		}
	}
`
export const FIND_MANY_USER = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findManyUser(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			phone
			code
			name
			pushId
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
		}
	}
`
export const FIND_MANY_USER_COUNT = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findManyUserCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_CURRENT_ADMIN = gql`
	{
		findCurrentAdmin{
			id
			createdAt
			updatedAt
			login
			password
		}
	}
`
export const FIND_UNIQUE_ADMIN = gql`
	query(
		$where: AdminWhereUniqueInput!
	){
		findUniqueAdmin(
			where: $where
		){
			id
			createdAt
			updatedAt
			login
			password
		}
	}
`
export const FIND_FIRST_ADMIN = gql`
	query(
		$where: AdminWhereInput
		$orderBy: [AdminOrderByWithRelationInput]
		$cursor: AdminWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminScalarFieldEnum]
	){
		findFirstAdmin(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			login
			password
		}
	}
`
export const FIND_MANY_ADMIN = gql`
	query(
		$where: AdminWhereInput
		$orderBy: [AdminOrderByWithRelationInput]
		$cursor: AdminWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminScalarFieldEnum]
	){
		findManyAdmin(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			login
			password
		}
	}
`
export const FIND_MANY_ADMIN_COUNT = gql`
	query(
		$where: AdminWhereInput
		$orderBy: [AdminOrderByWithRelationInput]
		$cursor: AdminWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminScalarFieldEnum]
	){
		findManyAdminCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_CURRENT_COMPANY = gql`
	{
		findCurrentCompany{
			id
			createdAt
			updatedAt
			email
			phone
			whatsapp
			password
			repassword
			name
			description
			city
			location
			address
			avatar
			images
		}
	}
`
export const FIND_UNIQUE_COMPANY = gql`
	query(
		$where: CompanyWhereUniqueInput!
	){
		findUniqueCompany(
			where: $where
		){
			id
			createdAt
			updatedAt
			email
			phone
			whatsapp
			password
			repassword
			name
			description
			city
			location
			address
			avatar
			images
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const FIND_FIRST_COMPANY = gql`
	query(
		$where: CompanyWhereInput
		$orderBy: [CompanyOrderByWithRelationInput]
		$cursor: CompanyWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CompanyScalarFieldEnum]
	){
		findFirstCompany(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			email
			phone
			whatsapp
			password
			repassword
			name
			description
			city
			location
			address
			avatar
			images
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_COMPANY = gql`
	query(
		$where: CompanyWhereInput
		$orderBy: [CompanyOrderByWithRelationInput]
		$cursor: CompanyWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CompanyScalarFieldEnum]
	){
		findManyCompany(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			email
			phone
			whatsapp
			password
			repassword
			name
			description
			city
			location
			address
			avatar
			images
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_COMPANY_COUNT = gql`
	query(
		$where: CompanyWhereInput
		$orderBy: [CompanyOrderByWithRelationInput]
		$cursor: CompanyWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CompanyScalarFieldEnum]
	){
		findManyCompanyCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_MASTER = gql`
	query(
		$where: MasterWhereUniqueInput!
	){
		findUniqueMaster(
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			description
			image
			companyId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			request{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
		}
	}
`
export const FIND_FIRST_MASTER = gql`
	query(
		$where: MasterWhereInput
		$orderBy: [MasterOrderByWithRelationInput]
		$cursor: MasterWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [MasterScalarFieldEnum]
	){
		findFirstMaster(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			name
			description
			image
			companyId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			request{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
		}
	}
`
export const FIND_MANY_MASTER = gql`
	query(
		$where: MasterWhereInput
		$orderBy: [MasterOrderByWithRelationInput]
		$cursor: MasterWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [MasterScalarFieldEnum]
	){
		findManyMaster(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			name
			description
			image
			companyId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			request{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
		}
	}
`
export const FIND_MANY_MASTER_COUNT = gql`
	query(
		$where: MasterWhereInput
		$orderBy: [MasterOrderByWithRelationInput]
		$cursor: MasterWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [MasterScalarFieldEnum]
	){
		findManyMasterCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_REQUEST = gql`
	query(
		$where: RequestWhereUniqueInput!
	){
		findUniqueRequest(
			where: $where
		){
			id
			createdAt
			updatedAt
			date
			times
			price
			status
			rating
			review
			code
			companyId
			userId
			masterId
			categoryId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			user{
				id
				createdAt
				updatedAt
				phone
				code
				name
				pushId
			}
			master{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
			}
		}
	}
`
export const FIND_FIRST_REQUEST = gql`
	query(
		$where: RequestWhereInput
		$orderBy: [RequestOrderByWithRelationInput]
		$cursor: RequestWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [RequestScalarFieldEnum]
	){
		findFirstRequest(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			date
			times
			price
			status
			rating
			review
			code
			companyId
			userId
			masterId
			categoryId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			user{
				id
				createdAt
				updatedAt
				phone
				code
				name
				pushId
			}
			master{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
			}
		}
	}
`
export const FIND_MANY_REQUEST = gql`
	query(
		$where: RequestWhereInput
		$orderBy: [RequestOrderByWithRelationInput]
		$cursor: RequestWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [RequestScalarFieldEnum]
	){
		findManyRequest(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			date
			times
			price
			status
			rating
			review
			code
			companyId
			userId
			masterId
			categoryId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			user{
				id
				createdAt
				updatedAt
				phone
				code
				name
				pushId
			}
			master{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
			}
		}
	}
`
export const FIND_MANY_REQUEST_COUNT = gql`
	query(
		$where: RequestWhereInput
		$orderBy: [RequestOrderByWithRelationInput]
		$cursor: RequestWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [RequestScalarFieldEnum]
	){
		findManyRequestCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_PROMOTION = gql`
	query(
		$where: PromotionWhereUniqueInput!
	){
		findUniquePromotion(
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			text
			image
		}
	}
`
export const FIND_FIRST_PROMOTION = gql`
	query(
		$where: PromotionWhereInput
		$orderBy: [PromotionOrderByWithRelationInput]
		$cursor: PromotionWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PromotionScalarFieldEnum]
	){
		findFirstPromotion(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			name
			text
			image
		}
	}
`
export const FIND_MANY_PROMOTION = gql`
	query(
		$where: PromotionWhereInput
		$orderBy: [PromotionOrderByWithRelationInput]
		$cursor: PromotionWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PromotionScalarFieldEnum]
	){
		findManyPromotion(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			name
			text
			image
		}
	}
`
export const FIND_MANY_PROMOTION_COUNT = gql`
	query(
		$where: PromotionWhereInput
		$orderBy: [PromotionOrderByWithRelationInput]
		$cursor: PromotionWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PromotionScalarFieldEnum]
	){
		findManyPromotionCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_SCHEDULE = gql`
	query(
		$where: ScheduleWhereUniqueInput!
	){
		findUniqueSchedule(
			where: $where
		){
			id
			createdAt
			updatedAt
			week
			times
			companyId
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const FIND_FIRST_SCHEDULE = gql`
	query(
		$where: ScheduleWhereInput
		$orderBy: [ScheduleOrderByWithRelationInput]
		$cursor: ScheduleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ScheduleScalarFieldEnum]
	){
		findFirstSchedule(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			week
			times
			companyId
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_SCHEDULE = gql`
	query(
		$where: ScheduleWhereInput
		$orderBy: [ScheduleOrderByWithRelationInput]
		$cursor: ScheduleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ScheduleScalarFieldEnum]
	){
		findManySchedule(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			week
			times
			companyId
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_SCHEDULE_COUNT = gql`
	query(
		$where: ScheduleWhereInput
		$orderBy: [ScheduleOrderByWithRelationInput]
		$cursor: ScheduleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ScheduleScalarFieldEnum]
	){
		findManyScheduleCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_SERVICE = gql`
	query(
		$where: ServiceWhereUniqueInput!
	){
		findUniqueService(
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			price
			companyId
			categoryId
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
		}
	}
`
export const FIND_FIRST_SERVICE = gql`
	query(
		$where: ServiceWhereInput
		$orderBy: [ServiceOrderByWithRelationInput]
		$cursor: ServiceWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ServiceScalarFieldEnum]
	){
		findFirstService(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			name
			price
			companyId
			categoryId
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
		}
	}
`
export const FIND_MANY_SERVICE = gql`
	query(
		$where: ServiceWhereInput
		$orderBy: [ServiceOrderByWithRelationInput]
		$cursor: ServiceWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ServiceScalarFieldEnum]
	){
		findManyService(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			name
			price
			companyId
			categoryId
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
		}
	}
`
export const FIND_MANY_SERVICE_COUNT = gql`
	query(
		$where: ServiceWhereInput
		$orderBy: [ServiceOrderByWithRelationInput]
		$cursor: ServiceWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ServiceScalarFieldEnum]
	){
		findManyServiceCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_CATEGORY = gql`
	query(
		$where: CategoryWhereUniqueInput!
	){
		findUniqueCategory(
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			subcategory
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const FIND_FIRST_CATEGORY = gql`
	query(
		$where: CategoryWhereInput
		$orderBy: [CategoryOrderByWithRelationInput]
		$cursor: CategoryWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CategoryScalarFieldEnum]
	){
		findFirstCategory(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			name
			subcategory
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_CATEGORY = gql`
	query(
		$where: CategoryWhereInput
		$orderBy: [CategoryOrderByWithRelationInput]
		$cursor: CategoryWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CategoryScalarFieldEnum]
	){
		findManyCategory(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			updatedAt
			name
			subcategory
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_CATEGORY_COUNT = gql`
	query(
		$where: CategoryWhereInput
		$orderBy: [CategoryOrderByWithRelationInput]
		$cursor: CategoryWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CategoryScalarFieldEnum]
	){
		findManyCategoryCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const CREATE_ONE_USER = gql`
	mutation(
		$data: UserCreateInput!
	){
		createOneUser(
			data: $data
		){
			id
			createdAt
			updatedAt
			phone
			code
			name
			pushId
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
		}
	}
`
export const UPDATE_ME_USER = gql`
	mutation(
		$data: UserUpdateMeInput!
	){
		updateMeUser(
			data: $data
		){
			id
			createdAt
			updatedAt
			phone
			code
			name
			pushId
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
		}
	}
`
export const UPDATE_ONE_USER = gql`
	mutation(
		$data: UserUpdateInput!
		$where: UserWhereUniqueInput!
	){
		updateOneUser(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			phone
			code
			name
			pushId
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
		}
	}
`
export const DELETE_ONE_USER = gql`
	mutation(
		$where: UserWhereUniqueInput!
	){
		deleteOneUser(
			where: $where
		){
			id
			createdAt
			updatedAt
			phone
			code
			name
			pushId
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
		}
	}
`
export const UPSERT_ONE_USER = gql`
	mutation(
		$where: UserWhereUniqueInput!
		$create: UserCreateInput!
		$update: UserUpdateInput!
	){
		upsertOneUser(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			phone
			code
			name
			pushId
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
		}
	}
`
export const DELETE_MANY_USER = gql`
	mutation(
		$where: UserWhereInput
	){
		deleteManyUser(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_USER = gql`
	mutation(
		$data: UserUpdateManyMutationInput!
		$where: UserWhereInput
	){
		updateManyUser(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const SEND_USER_PHONE = gql`
	mutation(
		$data: UserSendPhoneInput!
	){
		sendUserPhone(
			data: $data
		){
			id
			createdAt
			updatedAt
			phone
			code
			name
			pushId
		}
	}
`
export const SEND_USER_CODE = gql`
	mutation(
		$data: UserSendPhoneAndCodeInput!
	){
		sendUserCode(
			data: $data
		){
			user{
				id
			}
			token
		}
	}
`
export const SIGN_IN_ADMIN = gql`
	mutation(
		$data: AdminSignInInput!
	){
		signInAdmin(
			data: $data
		){
			token
		}
	}
`
export const CREATE_ONE_ADMIN = gql`
	mutation(
		$data: AdminCreateInput!
	){
		createOneAdmin(
			data: $data
		){
			id
			createdAt
			updatedAt
			login
			password
		}
	}
`
export const UPDATE_ONE_ADMIN = gql`
	mutation(
		$data: AdminUpdateInput!
		$where: AdminWhereUniqueInput!
	){
		updateOneAdmin(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			login
			password
		}
	}
`
export const DELETE_ONE_ADMIN = gql`
	mutation(
		$where: AdminWhereUniqueInput!
	){
		deleteOneAdmin(
			where: $where
		){
			id
			createdAt
			updatedAt
			login
			password
		}
	}
`
export const UPSERT_ONE_ADMIN = gql`
	mutation(
		$where: AdminWhereUniqueInput!
		$create: AdminCreateInput!
		$update: AdminUpdateInput!
	){
		upsertOneAdmin(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			login
			password
		}
	}
`
export const DELETE_MANY_ADMIN = gql`
	mutation(
		$where: AdminWhereInput
	){
		deleteManyAdmin(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_ADMIN = gql`
	mutation(
		$data: AdminUpdateManyMutationInput!
		$where: AdminWhereInput
	){
		updateManyAdmin(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const SIGN_IN_COMPANY = gql`
	mutation(
		$data: CompanySignInInput!
	){
		signInCompany(
			data: $data
		){
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			token
		}
	}
`
export const CREATE_ONE_COMPANY = gql`
	mutation(
		$data: CompanyCreateInput!
	){
		createOneCompany(
			data: $data
		){
			id
			createdAt
			updatedAt
			email
			phone
			whatsapp
			password
			repassword
			name
			description
			city
			location
			address
			avatar
			images
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const UPDATE_ONE_COMPANY = gql`
	mutation(
		$data: CompanyUpdateInput!
		$where: CompanyWhereUniqueInput!
	){
		updateOneCompany(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			email
			phone
			whatsapp
			password
			repassword
			name
			description
			city
			location
			address
			avatar
			images
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const DELETE_ONE_COMPANY = gql`
	mutation(
		$where: CompanyWhereUniqueInput!
	){
		deleteOneCompany(
			where: $where
		){
			id
			createdAt
			updatedAt
			email
			phone
			whatsapp
			password
			repassword
			name
			description
			city
			location
			address
			avatar
			images
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const UPSERT_ONE_COMPANY = gql`
	mutation(
		$where: CompanyWhereUniqueInput!
		$create: CompanyCreateInput!
		$update: CompanyUpdateInput!
	){
		upsertOneCompany(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			email
			phone
			whatsapp
			password
			repassword
			name
			description
			city
			location
			address
			avatar
			images
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const DELETE_MANY_COMPANY = gql`
	mutation(
		$where: CompanyWhereInput
	){
		deleteManyCompany(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_COMPANY = gql`
	mutation(
		$data: CompanyUpdateManyMutationInput!
		$where: CompanyWhereInput
	){
		updateManyCompany(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_MASTER = gql`
	mutation(
		$data: MasterCreateInput!
	){
		createOneMaster(
			data: $data
		){
			id
			createdAt
			updatedAt
			name
			description
			image
			companyId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			request{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
		}
	}
`
export const UPDATE_ONE_MASTER = gql`
	mutation(
		$data: MasterUpdateInput!
		$where: MasterWhereUniqueInput!
	){
		updateOneMaster(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			description
			image
			companyId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			request{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
		}
	}
`
export const DELETE_ONE_MASTER = gql`
	mutation(
		$where: MasterWhereUniqueInput!
	){
		deleteOneMaster(
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			description
			image
			companyId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			request{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
		}
	}
`
export const UPSERT_ONE_MASTER = gql`
	mutation(
		$where: MasterWhereUniqueInput!
		$create: MasterCreateInput!
		$update: MasterUpdateInput!
	){
		upsertOneMaster(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			name
			description
			image
			companyId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			request{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
		}
	}
`
export const DELETE_MANY_MASTER = gql`
	mutation(
		$where: MasterWhereInput
	){
		deleteManyMaster(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_MASTER = gql`
	mutation(
		$data: MasterUpdateManyMutationInput!
		$where: MasterWhereInput
	){
		updateManyMaster(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_REQUEST = gql`
	mutation(
		$data: RequestCreateInput!
	){
		createOneRequest(
			data: $data
		){
			id
			createdAt
			updatedAt
			date
			times
			price
			status
			rating
			review
			code
			companyId
			userId
			masterId
			categoryId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			user{
				id
				createdAt
				updatedAt
				phone
				code
				name
				pushId
			}
			master{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
			}
		}
	}
`
export const UPDATE_ONE_REQUEST = gql`
	mutation(
		$data: RequestUpdateInput!
		$where: RequestWhereUniqueInput!
	){
		updateOneRequest(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			date
			times
			price
			status
			rating
			review
			code
			companyId
			userId
			masterId
			categoryId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			user{
				id
				createdAt
				updatedAt
				phone
				code
				name
				pushId
			}
			master{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
			}
		}
	}
`
export const DELETE_ONE_REQUEST = gql`
	mutation(
		$where: RequestWhereUniqueInput!
	){
		deleteOneRequest(
			where: $where
		){
			id
			createdAt
			updatedAt
			date
			times
			price
			status
			rating
			review
			code
			companyId
			userId
			masterId
			categoryId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			user{
				id
				createdAt
				updatedAt
				phone
				code
				name
				pushId
			}
			master{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
			}
		}
	}
`
export const UPSERT_ONE_REQUEST = gql`
	mutation(
		$where: RequestWhereUniqueInput!
		$create: RequestCreateInput!
		$update: RequestUpdateInput!
	){
		upsertOneRequest(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			date
			times
			price
			status
			rating
			review
			code
			companyId
			userId
			masterId
			categoryId
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			user{
				id
				createdAt
				updatedAt
				phone
				code
				name
				pushId
			}
			master{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
			}
		}
	}
`
export const DELETE_MANY_REQUEST = gql`
	mutation(
		$where: RequestWhereInput
	){
		deleteManyRequest(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_REQUEST = gql`
	mutation(
		$data: RequestUpdateManyMutationInput!
		$where: RequestWhereInput
	){
		updateManyRequest(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_PROMOTION = gql`
	mutation(
		$data: PromotionCreateInput!
	){
		createOnePromotion(
			data: $data
		){
			id
			createdAt
			updatedAt
			name
			text
			image
		}
	}
`
export const UPDATE_ONE_PROMOTION = gql`
	mutation(
		$data: PromotionUpdateInput!
		$where: PromotionWhereUniqueInput!
	){
		updateOnePromotion(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			text
			image
		}
	}
`
export const DELETE_ONE_PROMOTION = gql`
	mutation(
		$where: PromotionWhereUniqueInput!
	){
		deleteOnePromotion(
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			text
			image
		}
	}
`
export const UPSERT_ONE_PROMOTION = gql`
	mutation(
		$where: PromotionWhereUniqueInput!
		$create: PromotionCreateInput!
		$update: PromotionUpdateInput!
	){
		upsertOnePromotion(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			name
			text
			image
		}
	}
`
export const DELETE_MANY_PROMOTION = gql`
	mutation(
		$where: PromotionWhereInput
	){
		deleteManyPromotion(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_PROMOTION = gql`
	mutation(
		$data: PromotionUpdateManyMutationInput!
		$where: PromotionWhereInput
	){
		updateManyPromotion(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_SCHEDULE = gql`
	mutation(
		$data: ScheduleCreateInput!
	){
		createOneSchedule(
			data: $data
		){
			id
			createdAt
			updatedAt
			week
			times
			companyId
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const UPDATE_ONE_SCHEDULE = gql`
	mutation(
		$data: ScheduleUpdateInput!
		$where: ScheduleWhereUniqueInput!
	){
		updateOneSchedule(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			week
			times
			companyId
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const DELETE_ONE_SCHEDULE = gql`
	mutation(
		$where: ScheduleWhereUniqueInput!
	){
		deleteOneSchedule(
			where: $where
		){
			id
			createdAt
			updatedAt
			week
			times
			companyId
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const UPSERT_ONE_SCHEDULE = gql`
	mutation(
		$where: ScheduleWhereUniqueInput!
		$create: ScheduleCreateInput!
		$update: ScheduleUpdateInput!
	){
		upsertOneSchedule(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			week
			times
			companyId
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				category{
					id
					createdAt
					updatedAt
					name
					subcategory
				}
			}
			masters{
				id
				createdAt
				updatedAt
				name
				description
				image
				companyId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				request{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				services{
					id
					createdAt
					updatedAt
					name
					price
					companyId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const DELETE_MANY_SCHEDULE = gql`
	mutation(
		$where: ScheduleWhereInput
	){
		deleteManySchedule(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_SCHEDULE = gql`
	mutation(
		$data: ScheduleUpdateManyMutationInput!
		$where: ScheduleWhereInput
	){
		updateManySchedule(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_SERVICE = gql`
	mutation(
		$data: ServiceCreateInput!
	){
		createOneService(
			data: $data
		){
			id
			createdAt
			updatedAt
			name
			price
			companyId
			categoryId
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
		}
	}
`
export const UPDATE_ONE_SERVICE = gql`
	mutation(
		$data: ServiceUpdateInput!
		$where: ServiceWhereUniqueInput!
	){
		updateOneService(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			price
			companyId
			categoryId
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
		}
	}
`
export const DELETE_ONE_SERVICE = gql`
	mutation(
		$where: ServiceWhereUniqueInput!
	){
		deleteOneService(
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			price
			companyId
			categoryId
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
		}
	}
`
export const UPSERT_ONE_SERVICE = gql`
	mutation(
		$where: ServiceWhereUniqueInput!
		$create: ServiceCreateInput!
		$update: ServiceUpdateInput!
	){
		upsertOneService(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			name
			price
			companyId
			categoryId
			schedules{
				id
				createdAt
				updatedAt
				week
				times
				companyId
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			company{
				id
				createdAt
				updatedAt
				email
				phone
				whatsapp
				password
				repassword
				name
				description
				city
				location
				address
				avatar
				images
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
				masters{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
			}
			category{
				id
				createdAt
				updatedAt
				name
				subcategory
				requests{
					id
					createdAt
					updatedAt
					date
					times
					price
					status
					rating
					review
					code
					companyId
					userId
					masterId
					categoryId
				}
			}
		}
	}
`
export const DELETE_MANY_SERVICE = gql`
	mutation(
		$where: ServiceWhereInput
	){
		deleteManyService(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_SERVICE = gql`
	mutation(
		$data: ServiceUpdateManyMutationInput!
		$where: ServiceWhereInput
	){
		updateManyService(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_CATEGORY = gql`
	mutation(
		$data: CategoryCreateInput!
	){
		createOneCategory(
			data: $data
		){
			id
			createdAt
			updatedAt
			name
			subcategory
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const UPDATE_ONE_CATEGORY = gql`
	mutation(
		$data: CategoryUpdateInput!
		$where: CategoryWhereUniqueInput!
	){
		updateOneCategory(
			data: $data
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			subcategory
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const DELETE_ONE_CATEGORY = gql`
	mutation(
		$where: CategoryWhereUniqueInput!
	){
		deleteOneCategory(
			where: $where
		){
			id
			createdAt
			updatedAt
			name
			subcategory
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const UPSERT_ONE_CATEGORY = gql`
	mutation(
		$where: CategoryWhereUniqueInput!
		$create: CategoryCreateInput!
		$update: CategoryUpdateInput!
	){
		upsertOneCategory(
			where: $where
			create: $create
			update: $update
		){
			id
			createdAt
			updatedAt
			name
			subcategory
			services{
				id
				createdAt
				updatedAt
				name
				price
				companyId
				categoryId
				schedules{
					id
					createdAt
					updatedAt
					week
					times
					companyId
				}
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
			}
			requests{
				id
				createdAt
				updatedAt
				date
				times
				price
				status
				rating
				review
				code
				companyId
				userId
				masterId
				categoryId
				company{
					id
					createdAt
					updatedAt
					email
					phone
					whatsapp
					password
					repassword
					name
					description
					city
					location
					address
					avatar
					images
				}
				user{
					id
					createdAt
					updatedAt
					phone
					code
					name
					pushId
				}
				master{
					id
					createdAt
					updatedAt
					name
					description
					image
					companyId
				}
			}
		}
	}
`
export const DELETE_MANY_CATEGORY = gql`
	mutation(
		$where: CategoryWhereInput
	){
		deleteManyCategory(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_CATEGORY = gql`
	mutation(
		$data: CategoryUpdateManyMutationInput!
		$where: CategoryWhereInput
	){
		updateManyCategory(
			data: $data
			where: $where
		){
			count
		}
	}
`